<template>
  <div class="list-filter">
    <div class="bg-white">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-4">
            <b-form-group
              id="input-group-territory"
              label="Территория"
              label-for="input-territory"
            >
              <b-form-input
                id="input-territory"
                v-model.trim="f.territory"
                placeholder="Территория"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group
              id="input-group-dealer"
              label="Дилер"
              label-for="input-dealer"
            >
              <b-form-input
                id="input-dealer"
                v-model.trim="f.dealer"
                placeholder="Дилер"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group
              id="input-group-brand"
              label="Марка"
              label-for="input-brand"
            >
              <b-form-input
                id="input-brand"
                v-model.trim="f.brand"
                placeholder="Марка"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
      </div>
      <div class="col-md-12 d-flex row-switches flex-wrap">
        <switch-checkbox
          v-model="f.is_salon_for_web"
          label="Это салон для сайта?"
        />
        <switch-checkbox v-model="f.is_active" label="Действ." />

        <switch-checkbox v-model="f.is_moto" label="Мото" />

        <switch-checkbox v-model="f.is_used" label="Used" />

        <switch-checkbox
          v-model="f.is_commercial_transport"
          label="Ком. транспорт "
        />
      </div>
    </div>
  </div>
</template>

<script>
import FiltersMixin from './FiltersMixin';
import SwitchCheckbox from '../SwitchCheckbox.vue';
export default {
  components: { SwitchCheckbox },
  mixins: [FiltersMixin],
  data() {
    return {
      f: {
        is_salon_for_web:
          this.$route.query?.is_salon_for_web === 'true' || false,
        is_active: this.$route.query?.is_active === 'true' || false,
        is_moto: this.$route.query?.is_moto === 'true' || false,
        is_used: this.$route.query?.is_used === 'true' || false,
        is_commercial_transport:
          this.$route.query?.is_commercial_transport === 'true' || false,
        territory: this.$route.query?.territory,
        dealer: this.$route.query?.dealer,
        brand: this.$route.query?.brand,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.list-filter {
  margin-bottom: 25px;

  .row-switches {
    @media (max-width: 658px) {
      flex-direction: column;
    }
    .custom-switch:not(:first-child) {
      @media (min-width: 659px) {
        margin-left: 25px;
      }
    }
  }
}
</style>
