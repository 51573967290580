<template>
  <div class="auto-dealer-table">
    <v-data-table
      :headers="headers"
      :items="items"
      disable-pagination
      item-key="name"
      class="elevation-1 row-links"
      hide-default-footer
      :header-props="{ 'sort-icon': 'mdi-sort-variant' }"
    >
      <template #item.is_salon_for_web="{ item }">
        <yes-no-badge :active="item.is_salon_for_web" />
      </template>

      <template #item.is_active="{ item }">
        <yes-no-badge :active="item.is_active" />
      </template>

      <template #item.is_moto="{ item }">
        <yes-no-badge :active="item.is_moto" />
      </template>

      <template #item.is_used="{ item }">
        <yes-no-badge :active="item.is_used" />
      </template>

      <template #item.is_commercial_transport="{ item }">
        <yes-no-badge :active="item.is_commercial_transport" />
      </template>

      <template #item.oid="{ item }">
        <router-link
          :to="{ name: urlName, params: { id: item.oid } }"
          title="Редактировать"
          event=""
          class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          @click.native.prevent="clickRow(item.oid)"
        >
          <span class="svg-icon svg-icon-md svg-icon-primary">
            <inline-svg src="/media/svg/icons/General/Edit.svg"></inline-svg>
          </span>
        </router-link>
      </template>

      <template #no-data>
        <div>Не найдены записи</div>
      </template>

      <template #no-results>
        <div>Не найдены записи</div>
      </template>
    </v-data-table>

    <b-modal v-model="modalShow" scrollable size="xl" @ok="saveForm">
      <template #modal-title>
        <h5>Редактирование {{ editItem.salon.name }}</h5>
      </template>

      <auto-dealer-form v-model="editItem" ref="autoForm" />

      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">
          Сохранить
        </b-button>
        <b-button size="sm" variant="danger" @click="cancel()">
          Отмена
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import YesNoBadge from '../YesNoBadge';
import { GET_ITEM } from '@/core/services/store/dealers.module';
import AutoDealerForm from '../forms/AutoDealerForm.vue';
export default {
  components: { YesNoBadge, AutoDealerForm },

  props: {
    items: {
      type: Array,
      default: () => [],
    },

    urlName: {
      type: String,
      deafult: () => null,
    },
  },

  data() {
    return {
      modalShow: false,
      editItem: null,
      headers: [
        { text: 'Дилер', value: 'dealer', sortable: true },
        { text: 'Марка', value: 'brand', sortable: true },
        { text: 'Территория', value: 'territory', sortable: true },
        {
          text: 'Это салон для сайта',
          value: 'is_salon_for_web',
          sortable: true,
        },
        { text: 'Действ.', value: 'is_active', sortable: true },
        { text: 'Мото', value: 'is_moto', sortable: true },
        { text: 'Used', value: 'is_used', sortable: true },
        {
          text: 'Ком. транспорт',
          value: 'is_commercial_transport',
          sortable: true,
        },
        { text: 'Действия', sortable: false, value: 'oid' },
      ],
    };
  },

  mounted() {
    this.$root.$on('bv::modal::hidden', () => {
      this.editItem = null;
    });
  },

  methods: {
    async clickRow(oid) {
      this.editItem = await this.$store.dispatch(GET_ITEM, oid);
      this.modalShow = true;
      return false;
    },

    saveForm() {
      this.$refs?.autoForm?.submit();
    },
  },
};
</script>

<style lang="scss"></style>
